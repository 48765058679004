import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getData, noopTransform, updateDayCall } from "../apiCalls";
import VenuesEditDialog from "./VenuesEditDialog";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    minWidth: 220,
  },
}));

export default function EditDialog(props) {
  const classes = useStyles();
  const [venue, setVenue] = useState("");
  const [trainer, setTrainer] = useState("");
  const [topic, setTopic] = useState("");

  const handleClose = () => {
    props.setDayToEdit(undefined);
  };

  const [updateDay] = useMutation(updateDayCall, {
    onSuccess: () => {
      props.refetchData();
      handleClose();
    },
  });

  useEffect(() => {
    if (props.dayToEdit) {
      setVenue(props.dayToEdit.venue_id);
      setTrainer(props.dayToEdit.trainer_id);
      setTopic(props.dayToEdit.topic);
    }
  }, [props.dayToEdit]);

  const handleSave = () => {
    updateDay({
      id: props.dayToEdit.id,
      item: {
        venue_id: venue,
        trainer_id: trainer,
        topic,
      },
    });
  };

  const { data: venues } = useQuery(
    ["venues", props.skillId],
    getData(noopTransform),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: trainers } = useQuery(
    ["trainers", props.liveSkillId],
    getData(noopTransform),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleChange = (event, setter) => {
    setter(event.target.value);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={props.dayToEdit !== undefined}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Tervezett felkészülési nap szerkesztése
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            component={"span"}
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <div>
              <TextField
                className={classes.formControl}
                id="topic"
                label="Téma"
                type="text"
                value={topic}
                disabled={props.editActual}
                onChange={(event) => handleChange(event, setTopic)}
              />
            </div>
            <div className="row">
              <div className="col">
                <FormControl className={classes.formControl}>
                  <InputLabel id="venue-select-label">Helyszín</InputLabel>
                  <Select
                    labelId="venue-select-label"
                    id="venue-select"
                    value={venue}
                    disabled={props.editActual}
                    onChange={(event) => handleChange(event, setVenue)}
                  >
                    {venues?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {!props.editActual && (
                <div className="col-auto">
                  <VenuesEditDialog skillId={props.skillId}></VenuesEditDialog>
                </div>
              )}
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel id="trainer-select-label">Felkészítő</InputLabel>
                <Select
                  labelId="trainer-select-label"
                  id="trainer-select"
                  value={trainer}
                  disabled={props.editActual}
                  onChange={(event) => handleChange(event, setTrainer)}
                >
                  {trainers?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Mégse
          </Button>
          <Button onClick={handleSave} color="primary">
            Mentés
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
