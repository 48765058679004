import React from "react";
import { Route, Switch } from "react-router-dom";
import Calendar from "../pages/Calendar";
import FillNew from "../pages/FillNew";
import Login from "../pages/Login";
import Report from "../pages/Report";
import Skills from "../pages/Skills";
//import PrivateRoute from "./PrivateRoute";

export default function Main() {
  return (
    <div className="my-3">
      <Switch>
        <Route path="/skills" render={(props) => <Skills {...props} />} />
        <Route path="/new/:id" render={(props) => <FillNew {...props} />} />
        <Route
          path="/calendar/:id"
          render={(props) => <Calendar {...props} />}
        />
        <Route path="/report/:id" render={(props) => <Report {...props} />} />
        <Route path="/">
          <Login />
        </Route>
      </Switch>
    </div>
  );
}
