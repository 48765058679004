import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  base: {
    fontSize: "15px",
    lineHeight: "20px",
    width: "20px",
    border: `1px solid ${theme.palette.secondary.dark}`,
  },
  checked: {
    background: theme.palette.secondary.main,
    color: "white",
  },
  unchecked: {
    background: "transparent",
    color: theme.palette.secondary.main,
  },
}));

export default function CharIcon(props) {
  const classes = useStyles();

  return (
    <div
      className={`${classes.base} ${props.checked ? classes.checked : classes}`}
    >
      {props.char}
    </div>
  );
}
