import {
  calculateBorder,
  calculateDayCardStyle,
} from "./calculateDayCardStyle";

const borderTop = ({ ctx, x, y, width, height, border }) => {
  ctx.fillStyle = border.color.top;
  ctx.fillRect(x, y, width, border.width.top);
};
const borderLeft = ({ ctx, x, y, width, height, border }) => {
  ctx.fillStyle = border.color.left;
  ctx.fillRect(x, y, border.width.left, height);
};
const borderBottom = ({ ctx, x, y, width, height, border }) => {
  ctx.fillStyle = border.color.bottom;
  ctx.fillRect(x, y + height - border.width.bottom, width, border.width.bottom);
};
const borderRight = ({ ctx, x, y, width, height, border }) => {
  ctx.fillStyle = border.color.right;
  ctx.fillRect(x + width - border.width.right, y, border.width.right, height);
};

const drawDay = ({ ctx, x, y, width, height, col, rowIndex, total }) => {
  const styles = calculateDayCardStyle(col);
  const border = calculateBorder(col, rowIndex, total);

  // Draw borders based on width
  [
    { w: border.width.top, fn: borderTop },
    { w: border.width.left, fn: borderLeft },
    { w: border.width.bottom, fn: borderBottom },
    { w: border.width.right, fn: borderRight },
  ]
    .sort((a, b) => a.w - b.w)
    .forEach((b) => b.fn({ ctx, x, y, width, height, border }));

  // Inner
  ctx.fillStyle = styles.backgroundColor;
  ctx.fillRect(
    x + border.width.left,
    y + border.width.top,
    width - border.width.left - border.width.right,
    height - border.width.top - border.width.bottom
  );
};

const findTooltip = (tooltips, e) =>
  tooltips.find(
    (r) =>
      r.x <= e.offsetX &&
      r.xe >= e.offsetX &&
      r.y <= e.offsetY &&
      r.ye >= e.offsetY
  );

export const drawPreview = (
  canvasRef,
  tooltipRef,
  fullCalendar,
  setStartOfMonth
) => {
  if (!canvasRef || !fullCalendar || !fullCalendar.length) return;

  const ctx = canvasRef.getContext("2d");
  canvasRef.width = canvasRef.offsetWidth;
  const width = Math.floor(canvasRef.width / 7);
  const height = Math.floor(canvasRef.offsetHeight / fullCalendar.length);

  const tooltips = [];

  canvasRef.onmouseout = function () {
    tooltipRef.style.display = "none";
  };
  canvasRef.onmousemove = function (e) {
    const tooltip = findTooltip(tooltips, e);
    if (!tooltip) return;
    tooltipRef.style.display = "block";
    tooltipRef.innerHTML = tooltip.label;
    tooltipRef.style.top = `${e.clientY - 32}px`;
    tooltipRef.style.left = `${e.clientX - tooltipRef.offsetWidth / 2 + 1}px`;
  };
  canvasRef.onclick = function (e) {
    const tooltip = findTooltip(tooltips, e);
    if (!tooltip) return;
    setStartOfMonth(tooltip.day.clone().startOf("month"));
  };

  fullCalendar.forEach((row, rowIndex) => {
    row.forEach((col, index) => {
      const x = index * width;
      const y = rowIndex * height;
      tooltips.push({
        x,
        y,
        xe: x + width,
        ye: y + height,
        day: col.day,
        label: `${col.day.format("MM/DD")}`,
      });
      drawDay({
        ctx,
        x,
        y,
        width,
        height,
        col,
        rowIndex,
        total: fullCalendar.length,
      });
    });
  });
};
