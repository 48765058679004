import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "./Auth";
import Header from "./components/Header";
import Main from "./components/Main";
import {
  getAuthData,
  removeAuthData,
  setAuthData,
  setupAxios,
} from "./setupAxios";

export default function App() {
  let history = useHistory();
  const [authData, setLocalAuthData] = useState(getAuthData());
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isSetup, setIsSetup] = useState(false);

  useEffect(() => {
    setupAxios(setIsLoggedIn);
    setIsSetup(true);
  }, []);

  useEffect(() => {
    setIsLoggedIn(!!authData);
    setIsReadOnly(authData?.role > 3);
  }, [authData]);

  useEffect(() => {
    if (isSetup && !isLoggedIn) {
      history.push("/");
    }
  }, [isLoggedIn, history, isSetup]);

  function handleLogin(data) {
    setAuthData(data);
    setLocalAuthData(data);
    history.push("/skills");
  }

  function handleSignOut() {
    setIsLoggedIn(false);
    removeAuthData();
  }

  return (
    <AuthContext.Provider
      value={{ isReadOnly, isLoggedIn, handleSignOut, handleLogin }}
    >
      <Header />
      {isSetup && <Main />}
    </AuthContext.Provider>
  );
}
