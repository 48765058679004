import React, { createRef, useEffect } from "react";
import { drawPreview } from "../utils/drawPreview";

export default function DayPreview(props) {
  const ref = createRef();
  const tooltipRef = createRef();

  useEffect(() => {
    drawPreview(
      ref.current,
      tooltipRef.current,
      props.fullCalendar,
      props.setStartOfMonth
    );
  }, [props.fullCalendar, props.setStartOfMonth, ref, tooltipRef]);

  return (
    <div className="mr-3">
      <canvas
        ref={ref}
        className="w-100 cursor-pointer"
        height={660}
        style={{ height: "660px" }}
      ></canvas>
      <div
        ref={tooltipRef}
        style={{ display: "none" }}
        className="position-fixed bg-white p-1 border"
      ></div>
    </div>
  );
}
