import { findIndex } from "lodash";

export const transformReport = (data) =>
  data.days.reduce((acc, row) => {
    const index = findIndex(acc, (x) => x.id === row.id);
    if (index === -1) {
      return [...acc, row];
    } else {
      if (acc[index].date_day !== row.date_day) {
        acc[index].date_day += ` & ${row.date_day}`;
      }
      return acc;
    }
  }, []);
