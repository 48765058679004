import axios from "axios";

const tokenName = "__sk_pr_d";

export const getAuthData = () => {
  const item = localStorage.getItem(tokenName);
  return item && JSON.parse(item);
};
export const setAuthData = (data) => {
  setAxiosHeader(data.token);
  localStorage.setItem(tokenName, JSON.stringify(data));
};
export const removeAuthData = () => {
  setAxiosHeader();
  localStorage.removeItem(tokenName);
};

const setAxiosHeader = (token) => {
  if (!!token) {
    axios.defaults.headers["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers["Authorization"];
  }
};

export const setupAxios = (setIsLoggedIn) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        setIsLoggedIn(false);
        removeAuthData();
      }
      return Promise.reject(error);
    }
  );
  setAxiosHeader(getAuthData()?.token);
};
