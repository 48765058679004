import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import MaterialTable from "material-table";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  addVenueCall,
  getData,
  noopTransform,
  updateVenueCall,
} from "../apiCalls";

export default function VenuesEditDialog(props) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const { data, isLoading, refetch } = useQuery(
    ["venues", props.skillId],
    getData(noopTransform),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [addVenue] = useMutation(addVenueCall, {
    onSuccess: refetch,
  });
  const [updateVenue] = useMutation(updateVenueCall, {
    onSuccess: refetch,
  });

  return (
    <>
      <Tooltip title="Felkészülési helyszínek hozzáadása és szerkesztése">
        <IconButton className="mt-3" onClick={() => setIsOpen(true)}>
          <EditIcon></EditIcon>
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={isOpen}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Felkészülési helyszínek hozzáadása és szerkesztése
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            component={"span"}
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <MaterialTable
              columns={[
                {
                  title: "Helyszín",
                  field: "name",
                  editable: "always",
                },
                {
                  title: "Cím",
                  field: "location",
                  editable: "always",
                },
              ]}
              data={data}
              isLoading={isLoading}
              title="Helyszínek"
              options={{
                paging: false,
                search: false,
                actionsColumnIndex: -1,
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: "Nincs adat",
                  addTooltip: "Új helyszín létrehozása",
                  editTooltip: "Szerkesztés",
                  editRow: {
                    cancelTooltip: "Mégse",
                    saveTooltip: "Mentés",
                  },
                },
                header: {
                  actions: "",
                },
              }}
              editable={{
                onRowAdd: (item) =>
                  addVenue({ item: { ...item, skill_id: props.skillId } }),
                onRowUpdate: ({ id, ...item }) =>
                  updateVenue({
                    id,
                    item: { ...item, skill_id: props.skillId },
                  }),
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Bezárás
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
