import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { first, last } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  getData,
  shiftDayCall,
  shiftDaysCall,
  transformData,
  transformLiveSkill,
  unshiftDayCall,
  unshiftDaysCall,
} from "../../apiCalls";
import { useAuth } from "../../Auth";
import DayCard from "../../components/DayCard";
import DayPreview from "../../components/DayPreview";
import DayCardDialog from "../../components/DayCardDialog";
import { generateCalendar, isOutOfRange } from "../../utils/transformCalendar";

export default function Calendar(props) {
  const { isReadOnly } = useAuth();
  const [startOfMonth, setStartOfMonth] = useState(moment().startOf("month"));
  const [fullCalendar, setFullCalendar] = useState([]);
  const [calendar, setCalendar] = useState([]);
  const [thisMonthCount, setThisMonthCount] = useState(0);
  const [dayToEdit, setDayToEdit] = useState(undefined);

  const { data, refetch: refetchData, isLoading: loadingData } = useQuery(
    ["data", props.match.params.id],
    getData(transformData),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: liveSkill, isLoading: loadingLiveSkill } = useQuery(
    ["live_skill", props.match.params.id],
    getData(transformLiveSkill),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [shiftDay, { isLoading: loadingShiftDay }] = useMutation(shiftDayCall, {
    onSuccess: refetchData,
  });
  const [
    shiftDays,
    { isLoading: loadingShiftDays },
  ] = useMutation(shiftDaysCall, { onSuccess: refetchData });
  const [
    unshiftDay,
    { isLoading: loadingUnshiftDay },
  ] = useMutation(unshiftDayCall, { onSuccess: refetchData });
  const [unshiftDays, { isLoading: loadingUnshiftDays }] = useMutation(
    unshiftDaysCall,
    {
      onSuccess: refetchData,
    }
  );

  // Calculate view for calendar
  useEffect(() => {
    if (data && liveSkill) {
      const calendar = generateCalendar(
        startOfMonth,
        startOfMonth.clone().endOf("month"),
        data,
        true
      );
      setCalendar(calendar);
      setThisMonthCount(
        calendar.reduce(
          (count, row) =>
            count +
            row.reduce(
              (rowC, c) => rowC + (c.isCurrentMonthPrepDay ? 1 : 0),
              0
            ),
          0
        )
      );

      const startOfPeriod = moment(liveSkill.prep_start).startOf("month");
      const endOfPeriod = moment(last(data.dates).date).endOf("month");
      setFullCalendar(generateCalendar(startOfPeriod, endOfPeriod, data));
    }
  }, [startOfMonth, liveSkill, data]);

  // Previous month
  function prev() {
    setStartOfMonth(startOfMonth.clone().subtract(1, "months"));
  }

  // Next month
  function next() {
    setStartOfMonth(startOfMonth.clone().add(1, "months"));
  }

  // Set if the day is training day
  function setIsTrainingDay({ day, isUnshift, amount }) {
    const id = props.match.params.id;
    isUnshift ? unshiftDay({ id, day, amount }) : shiftDay({ id, day, amount });
  }

  function setIsTrainingDayRange({ dayFrom, dayTo, isUnshift }) {
    const id = props.match.params.id;
    isUnshift
      ? unshiftDays({ id, dayFrom, dayTo })
      : shiftDays({ id, dayFrom, dayTo });
  }

  return (
    <>
      <DayCardDialog
        dayToEdit={dayToEdit}
        liveSkillId={liveSkill?.id}
        skillId={liveSkill?.skill_id}
        setDayToEdit={setDayToEdit}
        setIsTrainingDay={setIsTrainingDay}
        refetchData={refetchData}
      ></DayCardDialog>
      <Backdrop
        open={
          loadingData ||
          loadingLiveSkill ||
          loadingShiftDay ||
          loadingShiftDays ||
          loadingUnshiftDay ||
          loadingUnshiftDays
        }
        style={{ zIndex: 4200 }}
      >
        <CircularProgress />
      </Backdrop>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-10 mb-3">
            <h3 className="d-flex justify-content-between flex-wrap">
              <Button onClick={prev}>Előző hónap</Button>
              <span>
                {startOfMonth.format("YYYY. MMMM")} ({thisMonthCount})
                {false &&
                  !isReadOnly &&
                  !isOutOfRange(
                    first(last(calendar))?.day,
                    liveSkill?.prep_start,
                    data,
                    "month"
                  ) && (
                    <Checkbox
                      checked={calendar.reduce(
                        (found, row) =>
                          found ||
                          row
                            .filter((col) => col.isCurrentMonth)
                            .some((col) => !!col.data[0]),
                        false
                      )}
                      onChange={(event) =>
                        setIsTrainingDayRange({
                          dayFrom: startOfMonth,
                          dayTo: startOfMonth.clone().endOf("month"),
                          isUnshift: event.target.checked,
                        })
                      }
                    />
                  )}
                <small className="pl-3">
                  <Link to={`/report/${props.match.params.id}`}>Ütemterv</Link>
                </small>
              </span>
              <Button onClick={next}>Következő hónap</Button>
            </h3>
            {calendar.map((row, i2) => (
              <Grid container key={i2}>
                {[
                  <div
                    style={{ width: "42px" }}
                    key={i2}
                    className="d-none d-sm-block"
                  >
                    {!isReadOnly &&
                      !isOutOfRange(
                        last(row).day,
                        liveSkill.prep_start,
                        data,
                        "week"
                      ) && (
                        <Checkbox
                          checked={row.some((col) => !!col.data[0])}
                          onChange={(event) =>
                            setIsTrainingDayRange({
                              dayFrom: first(row).day,
                              dayTo: last(row).day,
                              isUnshift: event.target.checked,
                            })
                          }
                        />
                      )}
                  </div>,
                  row.map((col, index) => (
                    <DayCard
                      col={col}
                      key={index}
                      setIsTrainingDay={setIsTrainingDay}
                      setDayToEdit={setDayToEdit}
                      inlineMode={true}
                    />
                  )),
                ]}
              </Grid>
            ))}
          </div>
          <div className="col-md-2" style={{ maxWidth: "300px" }}>
            <h3 style={{ lineHeight: "36px" }}>Áttekintés</h3>
            <DayPreview
              fullCalendar={fullCalendar}
              monthRows={calendar?.length}
              setStartOfMonth={setStartOfMonth}
            />
          </div>
        </div>
      </div>
    </>
  );
}
