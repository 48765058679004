import red from "@material-ui/core/colors/red";
import teal from "@material-ui/core/colors/teal";
import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    primary: {
      main: red[900],
    },
    secondary: {
      main: teal[800],
    },
    success: {
      main: "#19b46e",
    },
  },
});
