import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../Auth";

export default function Header() {
  const { isLoggedIn, handleSignOut } = useAuth();

  return (
    <>
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            WorldSkills Hungary Felkészülés
          </Typography>
          {isLoggedIn && (
            <Link to="/skills" className="ml-3 text-white" color="inherit">
              <Button color="inherit">Felkészítések</Button>
            </Link>
          )}
          {isLoggedIn && (
            <Button color="inherit" className="ml-auto" onClick={handleSignOut}>
              Kilépés
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}
