import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../Auth";

const useStyles = makeStyles((theme) => ({
  login: {
    maxWidth: 500,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  let history = useHistory();
  const classes = useStyles();
  const { isLoggedIn, handleLogin } = useAuth();
  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (isLoggedIn) {
      history.push("/skills");
    }
  }, [isLoggedIn, history]);

  function _login(e) {
    e.preventDefault();
    setIsError(false);

    axios
      .post("login", {
        email,
        password,
      })
      .then((response) => handleLogin(response.data))
      .catch(() => setIsError(true));
  }

  return (
    <div className={`container ${classes.login}`}>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Belépés
        </Typography>
        <form className={classes.form} noValidate onSubmit={_login}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail cím"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Jelszó"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {isError && (
            <div className="alert alert-danger mb-0" role="alert">
              Hibás e-mail cím vagy jelszó.
            </div>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Belépés
          </Button>
        </form>
      </div>
    </div>
  );
}
