import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getData, noopTransform } from "../../apiCalls";

export default function Skills() {
  const { data: liveSkills } = useQuery(
    ["live_skill/mine"],
    getData(noopTransform),
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div className="container-fluid">
      {liveSkills?.map((liveSkill) => (
        <Card key={liveSkill.id} className="mt-3">
          <CardContent className="p-3">
            <h3>
              <Link
                to={`${liveSkill.prep_start ? "calendar" : "new"}/${
                  liveSkill.id
                }`}
              >
                {liveSkill.competition.name} - {liveSkill.skill.name}
                {liveSkill.label && ` - ${liveSkill.label}`}
              </Link>
            </h3>
            <p className="m-0">
              {liveSkill.prep_start} - {liveSkill.prep_end} |{" "}
              {liveSkill.prep_days_count} nap |{" "}
              {liveSkill.users.map((x) => x.name).join(" - ")}
            </p>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}
