import theme from "../theme";

export const calculateDayCardStyle = (col) => {
  if (!col.isCurrentMonth) {
    return { backgroundColor: "white", opacity: "0.6" };
  }
  if (col.isHighlighted) {
    return { backgroundColor: theme.palette.success.dark, color: "white" };
  }
  if (!!col.data && !!col.data[0] && !col.data[1]) {
    return { backgroundColor: theme.palette.success.light, color: "white" };
  }
  if (!!col.data && !!col.data[0]) {
    return { backgroundColor: theme.palette.success.main, color: "white" };
  }
  if (col.isHoliday) {
    return { backgroundColor: theme.palette.warning.light, color: "white" };
  }
  return { backgroundColor: "white" };
};

const isBoldTop = ({ day, weekDay, rowIndex }) =>
  rowIndex === 0 || day === 1 || (Math.abs(day - weekDay) < 7 && day <= 7);

const isBoldLeft = ({ day, weekDay }) => day === 1 || weekDay === 1;

const isBoldBottom = ({ rowIndex, total }) => rowIndex === total - 1;

const isBoldRight = ({ weekDay }) => weekDay === 7;

export const calculateBorder = (col, rowIndex, total) => {
  const day = parseInt(col.day.format("D"), 10);
  const weekDay = parseInt(col.day.format("d"), 10) || 7;

  const _isBoldTop = isBoldTop({ day, weekDay, rowIndex });
  const _isBoldLeft = isBoldLeft({ day, weekDay });
  const _isBoldBottom = isBoldBottom({ rowIndex, total });
  const _isBoldRight = isBoldRight({ weekDay });

  return {
    color: {
      top: _isBoldTop
        ? theme.palette.secondary.dark
        : theme.palette.success.light,
      left: _isBoldLeft
        ? theme.palette.secondary.dark
        : theme.palette.success.light,
      bottom: _isBoldBottom
        ? theme.palette.secondary.dark
        : theme.palette.success.light,
      right: _isBoldRight
        ? theme.palette.secondary.dark
        : theme.palette.success.light,
    },
    width: {
      top: _isBoldTop ? 2 : 1,
      left: _isBoldLeft ? 2 : 1,
      bottom: _isBoldBottom ? 2 : 1,
      right: _isBoldRight ? 2 : 1,
    },
  };
};
