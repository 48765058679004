import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import {
  extendLiveSkillCall,
  getData,
  noopTransform,
  transformLiveSkill,
} from "../../apiCalls";
import { useAuth } from "../../Auth";
import VenuesEditDialog from "../../components/VenuesEditDialog";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));

export default function FillNew(props) {
  let history = useHistory();
  const classes = useStyles();
  const { isReadOnly } = useAuth();
  const [venue, setVenue] = useState("");
  const [trainer, setTrainer] = useState("");
  const [prepStart, setPrepStart] = useState("");
  const [prepEnd, setPrepEnd] = useState("");

  const { data: liveSkill, isLoading: loadingLiveSkill } = useQuery(
    ["live_skill", props.match.params.id],
    getData(transformLiveSkill),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: venues } = useQuery(
    ["venues", liveSkill?.skill_id],
    getData(noopTransform),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: trainers } = useQuery(
    ["trainers", liveSkill?.id],
    getData(noopTransform),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleChange = (event, setter) => {
    setter(event.target.value);
  };

  const [extend, { isLoading: loadingSave }] = useMutation(
    extendLiveSkillCall,
    {
      onSuccess: () => history.push(`/calendar/${liveSkill.id}`),
    }
  );

  const save = () => {
    if (
      !venue ||
      !trainer ||
      !prepStart ||
      !prepEnd ||
      moment(prepEnd).isAfter(liveSkill.competition.date_from)
    ) {
      return;
    }

    const id = liveSkill.id;
    const item = {
      venue_id: venue,
      trainer_id: trainer,
      prep_start: prepStart,
      prep_end: prepEnd,
    };
    extend({ id, item });
  };

  return (
    <div className="container-fluid">
      <Backdrop open={loadingLiveSkill || loadingSave} style={{ zIndex: 4200 }}>
        {loadingSave && <h3>Felkészülési naptár feltöltése...&nbsp;</h3>}
        <CircularProgress />
      </Backdrop>
      {isReadOnly && <h3>Ez a felkészülés még nincs beállítva.</h3>}
      {!isReadOnly && liveSkill && (
        <Card>
          <CardContent>
            <h3>Felkészülés adatainak megadása</h3>
            <div className="d-flex align-items-end justify-content-between">
              <p className="mb-2">
                Szakma:
                <br />
                <b>{liveSkill.skill.name}</b>
              </p>
              <p className="mb-2">
                Verseny:
                <br />
                <b>{liveSkill.competition.name}</b>
              </p>
              <p className="mb-2">
                Napok száma:
                <br />
                <b>{liveSkill.prep_days_count}</b>
              </p>
              <FormControl className={classes.formControl}>
                <InputLabel id="venue-select-label">
                  Alapértelmezett helyszín
                </InputLabel>
                <Select
                  labelId="venue-select-label"
                  id="venue-select"
                  value={venue}
                  onChange={(event) => handleChange(event, setVenue)}
                >
                  {venues?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <VenuesEditDialog
                skillId={liveSkill?.skill_id}
              ></VenuesEditDialog>
              <FormControl className={classes.formControl}>
                <InputLabel id="trainer-select-label">
                  Alapértelmezett felkészítő
                </InputLabel>
                <Select
                  labelId="trainer-select-label"
                  id="trainer-select"
                  value={trainer}
                  onChange={(event) => handleChange(event, setTrainer)}
                >
                  {trainers?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                className={classes.formControl}
                id="prepStart"
                label="Felkészítés kezdete"
                type="date"
                value={prepStart}
                onChange={(event) => handleChange(event, setPrepStart)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                className={classes.formControl}
                id="prepEnd"
                label="Felkészítés vége"
                type="date"
                value={prepEnd}
                onChange={(event) => handleChange(event, setPrepEnd)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  max: liveSkill?.competition.date_from,
                }}
              />
              <Button className="mt-4" onClick={save}>
                Mentés
              </Button>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
}
