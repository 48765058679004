import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  addActualCall,
  getData,
  noopTransform,
  updateActualCall,
} from "../apiCalls";
import DayCard from "./DayCard";
import VenuesEditDialog from "./VenuesEditDialog";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    minWidth: 220,
  },
}));

export default function DayCardDialog(props) {
  const classes = useStyles();
  const [dayToEdit, setDayToEdit] = useState([]);
  const [venue, setVenue] = useState("");
  const [trainer, setTrainer] = useState("");
  const [topic, setTopic] = useState("");
  const [isFull, setIsFull] = useState(false);
  const [venueB, setVenueB] = useState("");
  const [trainerB, setTrainerB] = useState("");
  const [topicB, setTopicB] = useState("");
  const [actual, setActual] = useState();
  const [topicC, setTopicC] = useState("");
  const [notesC, setNotesC] = useState("");
  const [venueC, setVenueC] = useState("");
  const [trainerC, setTrainerC] = useState("");

  const handleClose = () => {
    props.setDayToEdit(undefined);
  };

  const [addActual] = useMutation(addActualCall, {
    onSuccess: () => {
      props.refetchData();
      handleClose();
    },
  });
  const [updateActual] = useMutation(updateActualCall, {
    onSuccess: () => {
      props.refetchData();
      handleClose();
    },
  });

  useEffect(() => {
    setDayToEdit(props.dayToEdit?.data);
    setActual(props.dayToEdit?.actual);
  }, [props.dayToEdit]);

  useEffect(() => {
    if (dayToEdit && dayToEdit[0]) {
      setVenue(dayToEdit[0].venue_id);
      setTrainer(dayToEdit[0].trainer_id);
      setTopic(dayToEdit[0].topic);

      if (dayToEdit[1] && dayToEdit[1].id !== dayToEdit[0].id) {
        setVenueB(dayToEdit[1].venue_id);
        setTrainerB(dayToEdit[1].trainer_id);
        setTopicB(dayToEdit[1].topic);
        setIsFull(false);
      } else {
        setIsFull(true);
      }
    }
  }, [dayToEdit]);

  useEffect(() => {
    if (actual) {
      setVenueC(actual.venue_id || "");
      setTrainerC(actual.trainer_id || "");
      setTopicC(actual.topic || "");
      setNotesC(actual.notes || "");
    } else {
      setVenueC("");
      setTrainerC("");
      setTopicC("");
      setNotesC("");
    }
  }, [actual]);

  const handleSave = () => {
    if (actual?.id) {
      updateActual({
        id: actual.id,
        item: {
          venue_id: !!venueC ? venueC : null,
          trainer_id: !!trainerC ? trainerC : null,
          topic: topicC,
          notes: notesC,
        },
      });
    } else {
      addActual({
        item: {
          live_skill_id: props.liveSkillId,
          day: dayToEdit[0].date_day,
          venue_id: !!venueC ? venueC : null,
          trainer_id: !!trainerC ? trainerC : null,
          topic: topicC,
          notes: notesC,
        },
      });
    }
  };

  const { data: venues } = useQuery(
    ["venues", props.skillId],
    getData(noopTransform),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: trainers } = useQuery(
    ["trainers", props.liveSkillId],
    getData(noopTransform),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleChange = (event, setter) => {
    setter(event.target.value);
  };

  const setIsTrainingDay = (...args) => {
    props.setIsTrainingDay(...args);
    handleClose();
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={props.dayToEdit !== undefined}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Felkészülési nap szerkesztése
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            component={"span"}
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            {props.dayToEdit && (
              <DayCard
                col={props.dayToEdit}
                setIsTrainingDay={setIsTrainingDay}
              ></DayCard>
            )}
            <div className="row mt-3">
              <div className={isFull ? "col-12" : "col-6"}>
                {!isFull && <h4>Ütemterv szerinti téma 1</h4>}
                {isFull && <h4>Ütemterv szerinti téma</h4>}
                <div>
                  <TextField
                    className={classes.formControl}
                    id="topic"
                    label="Téma"
                    type="text"
                    value={topic}
                    disabled={true}
                    onChange={(event) => handleChange(event, setTopic)}
                  />
                </div>
                <div>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="venue-select-label">Helyszín</InputLabel>
                    <Select
                      labelId="venue-select-label"
                      id="venue-select"
                      value={venue}
                      disabled={true}
                      onChange={(event) => handleChange(event, setVenue)}
                    >
                      {venues?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="trainer-select-label">
                      Felkészítő
                    </InputLabel>
                    <Select
                      labelId="trainer-select-label"
                      id="trainer-select"
                      value={trainer}
                      disabled={true}
                      onChange={(event) => handleChange(event, setTrainer)}
                    >
                      {trainers?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              {!isFull && (
                <div className="col-6">
                  <h4>Ütemterv szerinti téma 2</h4>
                  <div>
                    <TextField
                      className={classes.formControl}
                      id="topicB"
                      label="Téma"
                      type="text"
                      value={topicB}
                      disabled={true}
                      onChange={(event) => handleChange(event, setTopicB)}
                    />
                  </div>
                  <div>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="venue-select-label">Helyszín</InputLabel>
                      <Select
                        labelId="venue-select-label"
                        id="venue-selectB"
                        value={venueB}
                        disabled={true}
                        onChange={(event) => handleChange(event, setVenueB)}
                      >
                        {venues?.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="trainer-select-label">
                        Felkészítő
                      </InputLabel>
                      <Select
                        labelId="trainer-select-label"
                        id="trainer-selectB"
                        value={trainerB}
                        disabled={true}
                        onChange={(event) => handleChange(event, setTrainerB)}
                      >
                        {trainers?.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}
            </div>
            <h4 className="mt-2">Megvalósult téma (ha eltér a tervezettől)</h4>
            <div>
              <TextField
                className={classes.formControl}
                id="topicC"
                label="Téma"
                type="text"
                value={topicC}
                onChange={(event) => handleChange(event, setTopicC)}
              />
            </div>
            <div>
              <TextField
                className={classes.formControl}
                id="notesC"
                label="Megjegyzés"
                type="text"
                value={notesC}
                onChange={(event) => handleChange(event, setNotesC)}
              />
            </div>
            <div className="row">
              <div className="col">
                <FormControl className={classes.formControl}>
                  <InputLabel id="venue-select-label">Helyszín</InputLabel>
                  <Select
                    labelId="venue-select-label"
                    id="venue-selectC"
                    value={venueC}
                    onChange={(event) => handleChange(event, setVenueC)}
                  >
                    {venues?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-auto">
                <VenuesEditDialog skillId={props.skillId}></VenuesEditDialog>
              </div>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel id="trainer-select-label">Felkészítő</InputLabel>
                <Select
                  labelId="trainer-select-label"
                  id="trainer-selectC"
                  value={trainerC}
                  onChange={(event) => handleChange(event, setTrainerC)}
                >
                  {trainers?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Mégse
          </Button>
          <Button onClick={handleSave} color="primary">
            Mentés
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
