import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import HouseRoundedIcon from "@material-ui/icons/HouseRounded";
import PersonPinRoundedIcon from "@material-ui/icons/PersonPinRounded";
import React, { useEffect, useState } from "react";
import { useAuth } from "../Auth";
import { calculateDayCardStyle } from "../utils/calculateDayCardStyle";
import DayCardHeader from "./DayCardHeader";

const useStyles = (inlineMode, mini) =>
  makeStyles((theme) => ({
    gridColumn: {
      width: inlineMode
        ? "calc(14.285714285714285714285714285714% - 6px)"
        : "100%",
    },
    gridColumnEditable: {
      cursor: inlineMode ? "pointer" : "initial",
    },
    paperItem: {
      width: "100%",
      height: mini ? "24px" : "100%",
      boxSizing: "border-box",
      padding: theme.spacing(0.5),
      border: `1px solid ${theme.palette.secondary.dark}`,
    },
    paperBody: {
      paddingLeft: theme.spacing(0.25),
      paddingTop: theme.spacing(0.5),
      minHeight: "70px",
    },
    dayNo: {
      paddingLeft: theme.spacing(0.75),
      paddingRight: theme.spacing(0.75),
      marginRight: theme.spacing(0.5),
      fontSize: "15px",
      lineHeight: "20px",
      border: `1px solid ${theme.palette.secondary.dark}`,
      background: theme.palette.secondary.main,
      color: "white",
    },
  }));

export default function DayCard(props) {
  const { isReadOnly } = useAuth();
  const classes = useStyles(props.inlineMode, props.mini)();
  const [isDifferent, setIsDifferent] = useState(false);

  useEffect(() => {
    if (
      !!props.col.data[1]?.id &&
      props.col.data[0]?.id !== props.col.data[1]?.id
    ) {
      setIsDifferent(true);
    } else {
      setIsDifferent(false);
    }
  }, [props.col]);

  const setDayToEdit = (item) => {
    if (!isReadOnly && props.inlineMode && props.col.isAllowedToSelect) {
      props.setDayToEdit(item);
    }
  };

  return (
    <Grid
      item
      className={`${classes.gridColumn} ${
        !isReadOnly && props.col.isAllowedToSelect && classes.gridColumnEditable
      }`}
      onClick={() => setDayToEdit(props.col)}
    >
      <Paper
        elevation={0}
        variant="outlined"
        square
        className={classes.paperItem}
        style={calculateDayCardStyle(props.col)}
      >
        <div className="d-flex flex-column w-100 h-100">
          <div className="flex-grow-1">
            {!props.mini && (
              <DayCardHeader
                day={props.col.day}
                data={props.col.data}
                setIsTrainingDay={props.setIsTrainingDay}
                inlineMode={props.inlineMode}
                isEdit={!isReadOnly && props.col.isAllowedToSelect}
              ></DayCardHeader>
            )}
            <div
              className={`${classes.paperBody} ${
                props.inlineMode && "d-none d-sm-block"
              }`}
            >
              {!props.mini && props.col.data[0] && (
                <>
                  <p className="mb-1">
                    {props.col.data[0].topic}
                    {isDifferent &&
                      props.col.data[1] &&
                      ` / ${props.col.data[1].topic}`}
                  </p>
                  {props.col.actual && (
                    <p className="mb-1">{props.col.actual.topic}</p>
                  )}
                </>
              )}
              {!props.mini && props.col.holiday && (
                <p>{props.col.holiday.notes}</p>
              )}
            </div>
          </div>
          <div
            className={`${
              props.inlineMode ? "d-none d-lg-flex" : "d-flex"
            } justify-content-between flex-wrap`}
          >
            <div className="d-flex flex-grow-1">
              {props.col.data[0] && (
                <div className={classes.dayNo}>{props.col.data[0].day_no}</div>
              )}
              {isDifferent && props.col.data[1] && (
                <div className={classes.dayNo}>{props.col.data[1].day_no}</div>
              )}
            </div>
            <div>
              {props.col.data[0] && (
                <>
                  <Tooltip
                    title={
                      props.col.actual?.trainer?.name ||
                      props.col.data[0]?.trainer?.name
                    }
                    placement="top"
                  >
                    <PersonPinRoundedIcon></PersonPinRoundedIcon>
                  </Tooltip>
                  <Tooltip
                    title={
                      props.col.actual?.venue?.name ||
                      props.col.data[0]?.venue?.name
                    }
                    placement="top"
                  >
                    <HouseRoundedIcon></HouseRoundedIcon>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        </div>
      </Paper>
    </Grid>
  );
}
