import axios from "axios";
import moment from "moment";

const API_DATE_FORMAT = "YYYY-MM-DD";

const transformDate = (row) => ({
  ...row,
  no: `${row.day_no}. nap`,
  date: moment(row.date_day, API_DATE_FORMAT).toISOString(),
});
export const transformData = ({ days, dates, actuals }) => ({
  days: days.map(transformDate),
  dates: dates.map(transformDate),
  actuals: actuals.map((r) => ({
    ...r,
    date: moment(r.day, API_DATE_FORMAT).toISOString(),
  })),
});
export const transformLiveSkill = (liveSkill) => ({
  ...liveSkill,
  prep_start: moment(liveSkill.prep_start, API_DATE_FORMAT).toISOString(),
});
export const transformIdNamePairs = (data) =>
  data?.reduce((acc, row) => ({ ...acc, [row.id]: row.name }), {});
export const noopTransform = (data) => data;

export const getData = (transformFn) => async (...params) => {
  if (params.length !== params.filter((x) => !!x).length) return undefined;
  const { data } = await axios.get(params.join("/"));
  return transformFn(data);
};

export const shiftDayCall = async ({ id, day, amount }) => {
  const { data } = await axios.put(`shift_day/${id}/${day}/${amount}`);
  return data;
};
export const shiftDaysCall = async ({ id, dayFrom, dayTo }) => {
  const { data } = await axios.put(`shift_days/${id}/${dayFrom}/${dayTo}`);
  return data;
};
export const unshiftDayCall = async ({ id, day, amount }) => {
  const { data } = await axios.put(`unshift_day/${id}/${day}/${amount}`);
  return data;
};
export const unshiftDaysCall = async ({ id, dayFrom, dayTo }) => {
  const { data } = await axios.put(`unshift_days/${id}/${dayFrom}/${dayTo}`);
  return data;
};

export const extendLiveSkillCall = async ({ id, item }) => {
  const { data } = await axios.put(`live_skill/${id}/extend`, item);
  return data;
};
export const updateDayCall = async ({ id, item }) => {
  const { data } = await axios.put(`day/${id}`, item);
  return data;
};

export const addVenueCall = async ({ item }) => {
  const { data } = await axios.post(`venue`, item);
  return data;
};
export const updateVenueCall = async ({ id, item }) => {
  const { data } = await axios.put(`venue/${id}`, item);
  return data;
};

export const addActualCall = async ({ item }) => {
  const { data } = await axios.post(`actual`, item);
  return data;
};
export const updateActualCall = async ({ id, item }) => {
  const { data } = await axios.put(`actual/${id}`, item);
  return data;
};
