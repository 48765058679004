import { first, last } from "lodash";
import moment from "moment";

export const generateCalendar = (
  startOfPeriod,
  endOfPeriod,
  rawData,
  isMonthly
) => {
  const { days, dates, actuals } = rawData;
  const prepStart = first(dates)?.date;
  const prepEnd = last(dates)?.date;
  const lastDay = last(days)?.date;
  const today = moment().startOf("day").toISOString();
  return Array(weeksInPeriod(startOfPeriod, endOfPeriod))
    .fill(null)
    .map((_, row) =>
      Array(7)
        .fill(startOfPeriod.clone().startOf("week"))
        .map((startOfWeek, index) =>
          startOfWeek.clone().add(index + row * 7, "days")
        )
        .map((day) => {
          const isoDay = day.toISOString();
          const data = days?.filter((r) => r.date === isoDay);
          const meta = dates?.find((r) => r.date === isoDay);
          const actual = actuals?.find((r) => r.date === isoDay);
          const isToday = today === isoDay;
          const isHoliday =
            meta?.level_default === 0 || meta?.level_default === 2;
          const isForcedHoliday = meta?.level_default === 2;
          const isOutOfRange = isoDay < prepStart || isoDay > prepEnd;
          const isOutOfSelection = isoDay < prepStart || isoDay > lastDay;

          return {
            day,
            data,
            actual,
            isHoliday,
            isOutOfRange,
            isOutOfSelection,
            isAllowedToSelect: !isForcedHoliday && !isOutOfSelection,
            isHighlighted: isToday,
          };
        })
        .map((day) => {
          if (isMonthly) {
            const isCurrentMonth = day.day.isSame(startOfPeriod, "month");
            return {
              ...day,
              isCurrentMonth,
              isCurrentMonthPrepDay:
                !!day.data && day.data[0] && isCurrentMonth,
            };
          } else {
            return {
              ...day,
              isCurrentMonth: !day.isOutOfRange,
              isCurrentMonthPrepDay:
                !!day.data && day.data[0] && !day.isOutOfRange,
            };
          }
        })
    );
};

const weeksInPeriod = (startOfPeriod, endOfPeriod) =>
  endOfPeriod
    .clone()
    .endOf("week")
    .diff(startOfPeriod.clone().startOf("week"), "week") + 1;

export const isOutOfRange = (day, prepStart, data, period) => {
  const isBeforeFirst = day && day.isBefore(prepStart, period);
  const isAfterLast = day && day.isAfter(last(data.dates).date, period);
  return isBeforeFirst || isAfterLast;
};
