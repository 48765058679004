import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import CharIcon from "./CharIcon";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: theme.spacing(0.25),
  },
  paperHeader: {
    display: "flex",
    flexWrap: "wrap-reverse",
  },
  paperDay: {
    flex: "1",
    paddingTop: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    fontSize: "14px",
    lineHeight: "20px",
    color: "white",
    background: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.dark}`,
    textAlign: "center",
    textTransform: "uppercase",
  },
}));

export default function DayCardHeader(props) {
  const classes = useStyles();

  const stopPropagation = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const unshift = (amount) => {
    props.setIsTrainingDay({
      day: props.day,
      isUnshift: true,
      amount,
    });
  };

  const shift = (amount) => {
    props.setIsTrainingDay({
      day: props.day,
      isUnshift: false,
      amount,
    });
  };

  const handleChangeFull = () => {
    // IF none is checked, unshift full day
    if (!props.data[0]) {
      unshift(2);
    }
    // IF half day is checked, unshift half day
    else if (!props.data[1]) {
      unshift(1);
    }
    // IF full day is checked, shift full day
    else {
      shift(2);
    }
  };

  const handleChangeHalf = () => {
    // IF none is checked, unshift half day
    if (!props.data[0]) {
      unshift(1);
    }
    // IF full day is checked, shift half day
    else if (!!props.data[1]) {
      shift(1);
    }
    // IF half day is checked, shift half day
    else {
      shift(1);
    }
  };

  return (
    <div className={classes.paperHeader}>
      {props.isEdit ? (
        <div className={props.inlineMode && `d-none d-sm-block`}>
          <Tooltip title="Egész nap?">
            <Checkbox
              className={classes.checkbox}
              icon={<CharIcon char="E" checked={false}></CharIcon>}
              checkedIcon={<CharIcon char="E" checked={true}></CharIcon>}
              checked={!!props.data[0] && !!props.data[1]}
              onClick={stopPropagation}
              onChange={handleChangeFull}
            />
          </Tooltip>
          <Tooltip title="Fél nap?">
            <Checkbox
              className={classes.checkbox}
              icon={<CharIcon char="F" checked={false}></CharIcon>}
              checkedIcon={<CharIcon char="F" checked={true}></CharIcon>}
              checked={!!props.data[0] && !props.data[1]}
              onClick={stopPropagation}
              onChange={handleChangeHalf}
            />
          </Tooltip>
        </div>
      ) : null}
      <div className={`${classes.paperDay} d-none d-sm-block`}>
        {props.day.format("MM/DD ddd")}
      </div>
      <div className={`${classes.paperDay} d-block d-sm-none`}>
        {props.day.format("MM. DD.")}
      </div>
    </div>
  );
}
