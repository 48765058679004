import MaterialTable from "material-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  getData,
  noopTransform,
  transformData,
  transformIdNamePairs,
  transformLiveSkill,
  updateDayCall,
} from "../../apiCalls";
import { useAuth } from "../../Auth";
import EditDialog from "../../components/EditDialog";
import { transformReport } from "../../utils/transformReport";

export default function Report(props) {
  const { isReadOnly } = useAuth();
  const [report, setReport] = useState([]);
  const [dayToEdit, setDayToEdit] = useState(undefined);

  const { data, refetch: refetchData, isLoading: isDataLoading } = useQuery(
    ["data", props.match.params.id],
    getData(transformData),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: liveSkill } = useQuery(
    ["live_skill", props.match.params.id],
    getData(transformLiveSkill),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: venues } = useQuery(
    ["venues", liveSkill?.skill_id],
    getData(noopTransform),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: trainers } = useQuery(
    ["trainers", liveSkill?.id],
    getData(noopTransform),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [updateDay] = useMutation(updateDayCall, {
    onSuccess: refetchData,
  });

  useEffect(() => {
    if (data) {
      setReport(transformReport(data));
    }
  }, [data]);

  return (
    <>
      <EditDialog
        dayToEdit={dayToEdit}
        liveSkillId={liveSkill?.id}
        skillId={liveSkill?.skill_id}
        setDayToEdit={setDayToEdit}
        refetchData={refetchData}
      ></EditDialog>
      <div className="container-fluid">
        <h3 className="text-center">
          {moment(liveSkill?.prep_start).format("YYYY.")}
          <small className="pl-3">
            <Link to={`/calendar/${props.match.params.id}`}>
              Felkészülési naptár
            </Link>
          </small>
        </h3>
        <MaterialTable
          columns={[
            { title: "#", field: "no", editable: "never" },
            {
              title: "Dátum",
              field: "date_day",
              editable: "never",
            },
            { title: "Felkészítés témája", field: "topic", editable: "always" },
            {
              title: "Helyszín",
              field: "venue_id",
              editable: "always",
              lookup: transformIdNamePairs(venues),
            },
            {
              title: "Szakember",
              field: "trainer_id",
              editable: "always",
              lookup: transformIdNamePairs(trainers),
            },
          ]}
          editable={
            !isReadOnly
              ? {
                  onBulkUpdate: (changes) =>
                    Promise.all(
                      Object.values(changes).map(({ newData: item }) =>
                        updateDay({
                          id: item.id,
                          item,
                        })
                      )
                    ),
                  onRowUpdate: (item) =>
                    updateDay({
                      id: item.id,
                      item,
                    }),
                }
              : {}
          }
          actions={
            !isReadOnly
              ? [
                  {
                    icon: "launch",
                    tooltip: "Részletes szerkesztés",
                    onClick: (event, rowData) => {
                      setDayToEdit(rowData);
                    },
                  },
                ]
              : []
          }
          data={report}
          isLoading={isDataLoading}
          title="Ütemterv"
          options={{
            paging: false,
            exportButton: !isReadOnly,
            exportFileName: "Skills Hungary Ütemterv",
            actionsColumnIndex: -1,
          }}
          localization={{
            toolbar: {
              searchTooltip: "Keresés",
              searchPlaceholder: "Keresés",
            },
            body: {
              emptyDataSourceMessage: "Nincs adat",
              editTooltip: "Szerkesztés",
              bulkEditTooltip: "Összes sor szerkesztése",
              bulkEditApprove: "Összes sor mentése",
              bulkEditCancel: "Összes sor visszaállítása",
              editRow: {
                cancelTooltip: "Mégse",
                saveTooltip: "Mentés",
              },
            },
            header: {
              actions: "",
            },
          }}
        />
      </div>
    </>
  );
}
